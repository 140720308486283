// If you want to override variables do it here
@import "variables";

// Import styles with default layout.
// If you are going to use dark layout please comment next line
@import "~@coreui/coreui-pro/scss/coreui.scss";

// Import styles with dark layout
// If you want to use dark layout uncomment next line
//@import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui-pro/scss/_dropdown-menu-right.scss";

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

// temp fixes
@import "fixes";

// Spinkit
$spinkit-spinner-color: $body-color;
@import '~spinkit/scss/spinkit.scss';

.date-form-control {
    position: relative;

    &__overlay-wrapper {
        position: absolute;
        border: 1px solid #e4e7ea;
        background-color: #fff;
        z-index: 2000;
    }

    input {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #5c6873;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #e4e7ea;
        border-radius: 0.25rem;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
}

span.time-form-control {
    display: block;

    input {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #5c6873;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #e4e7ea;
        border-radius: 0.25rem;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
}

.rc-time-picker-panel {
    .rc-time-picker-panel-inner {
        box-shadow: none;
        border: none;
        background: none;
        margin-top: 6px;
        margin-left: 6px;
    }

    .rc-time-picker-panel-combobox {
        display: flex;
        box-shadow: 0 1px 5px #ccc;
        border: 1px solid #ccc;
        background: #fff;
    }

    .rc-time-picker-panel-select {
        float: none;
    }

    .rc-time-picker-panel-input-invalid {
        border: none;
    }
}

.app-header .navbar-brand {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -77.5px;
}

.spinned-container {
    position: relative;
    z-index: 0;

    .spinner-overlay {
        position: absolute;
        z-index: 1;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;

        background-color: rgba(255,255,255,.7);
    }
}

.sidebar-minimized .sidebar .nav-dropdown-items .nav-link {
    padding-left: 8px;
}

.settings-breadcrumb-button {
    margin-right: 0.75rem;
    margin-top: calc(-1.5rem - 40px);
}

@media screen and (max-width: 767px) {
    .main .container-fluid {
        padding: 0 8px;
    }
}
